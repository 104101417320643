html,
body {
  height: auto !important;
}

.ant-layout-has-sider>button {
  top: 0 !important;
  border-top-right-radius: 0 !important;
}

/* main>div {
  padding: 0 !important;
} */